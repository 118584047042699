import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import '../components/global.css'
import { BeerRender } from '../components/monitorStyle_rp.js'

class Monitor2 extends React.Component {
  render() {
    const beerTaps = get(this, 'props.data.contentfulTappingList.list')
    const availableList = [
      get(this, 'props.data.contentfulTappingList.tap5'),
      get(this, 'props.data.contentfulTappingList.tap6'),
      get(this, 'props.data.contentfulTappingList.tap7'),
      get(this, 'props.data.contentfulTappingList.tap8'),
    ]

    return (
      // <Layout title="Our Menu" location={this.props.location}>
      <BeerRender
        beerTaps={beerTaps}
        startIndex={4}
        availableList={availableList}
      />
      // </Layout>
    )
  }
}

export default Monitor2

export const pageQuery = graphql`
  query LP_TappingQuery2 {
    contentfulTappingList(contentful_id: { eq: "4CvogBULpgyQuJ1KSkvDmg" }) {
      list {
        brand
        brandFontSize
        name
        detail
        type
        alcoholPercentage
        price
        pricePint
        specialPrice
        image {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100)
          fluid {
            src
          }
        }
        country {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      tap5
      tap6
      tap7
      tap8
    }
  }
`
